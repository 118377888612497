define("discourse/plugins/discourse-events/discourse/initializers/discourse-events", ["exports", "@ember/object", "@ember/runloop", "jquery", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/models/provider"], function (_exports, _object, _runloop, _jquery, _pluginApi, _composer, _decorators, _I18n, _provider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "events-edits",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const currentUser = container.lookup("service:current-user");
      container.registry.register("model:provider", _provider.default);
      (0, _pluginApi.withPluginApi)("1.4.0", api => {
        api.serializeToDraft("event");
        api.serializeOnCreate("event");
        api.serializeToTopic("event", "topic.event");
        api.addDiscoveryQueryParam("end", {
          replace: true,
          refreshModel: true
        });
        api.addDiscoveryQueryParam("start", {
          replace: true,
          refreshModel: true
        });
        api.modifyClass("model:composer", dt7948.p({
          pluginId: "discourse-events",
          showEventControls(subtype, categoryEnabled, topicFirstPost, event, canCreateEvent) {
            return topicFirstPost && (subtype === "event" || categoryEnabled || event) && canCreateEvent;
          },
          canCreateEvent(minTrust) {
            return currentUser.staff || currentUser.trust_level >= minTrust;
          }
        }, [["method", "showEventControls", [(0, _decorators.default)("subtype", "category.events_enabled", "topicFirstPost", "topic.event", "canCreateEvent")]], ["method", "canCreateEvent", [(0, _decorators.default)("category.events_min_trust_to_create")]]]));
        api.modifyClass("component:composer-body", dt7948.p({
          pluginId: "discourse-events",
          resizeWhenEventAdded() {
            this.composerResized();
          },
          showEventControls() {
            const showControls = this.get("composer.showEventControls");
            const $container = (0, _jquery.default)(".composer-fields .title-and-category");
            $container.toggleClass("show-event-controls", Boolean(showControls));
            if (showControls) {
              const $anchor = this.site.mobileView ? $container.find(".title-input") : $container;
              (0, _jquery.default)(".composer-controls-event").appendTo($anchor);
            }
            this.composerResized();
          },
          applyEventInlineClass() {
            (0, _runloop.scheduleOnce)("afterRender", this, this.showEventControls);
          }
        }, [["method", "resizeWhenEventAdded", [(0, _decorators.observes)("composer.event")]], ["method", "applyEventInlineClass", [(0, _decorators.observes)("composer.showEventControls", "composer.composeState")]]]));
        api.modifyClass("model:topic", dt7948.p({
          pluginId: "discourse-events",
          showEventControls(subtype, categoryEnabled, canCreateEvent) {
            return (subtype === "event" || categoryEnabled) && canCreateEvent;
          },
          canCreateEvent(minTrust) {
            return currentUser.staff || currentUser.trust_level >= minTrust;
          },
          topicListItemClasses(lastRead, highest) {
            let classes = "date-time title raw-link event-link raw-topic-link";
            if (lastRead === highest) {
              classes += " visited";
            }
            return classes;
          }
        }, [["method", "showEventControls", [(0, _decorators.default)("subtype", "category.events_enabled", "canCreateEvent")]], ["method", "canCreateEvent", [(0, _decorators.default)("category.events_min_trust_to_create")]], ["method", "topicListItemClasses", [(0, _decorators.default)("last_read_post_number", "highest_post_number")]]]));

        // necessary because topic-title plugin outlet only recieves model
        api.modifyClass("controller:topic", dt7948.p({
          pluginId: "discourse-events",
          setEditingTopicOnModel() {
            this.set("model.editingTopic", this.get("editingTopic"));
          }
        }, [["method", "setEditingTopicOnModel", [(0, _decorators.observes)("editingTopic")]]]));
        api.modifyClass("route:discovery.category", {
          pluginId: "discourse-events",
          afterModel(model) {
            if (model.filterType === "calendar" && this.templateName === "discovery/list") {
              this.templateName = "discovery/calendar";
            }
          }
        });
        api.addNavigationBarItem({
          name: "calendar",
          displayName: "Calendar",
          customFilter: category => {
            return siteSettings.events_calendar_enabled || category && category.events_calendar_enabled;
          },
          customHref: category => {
            if (category) {
              return `${category.url}/l/calendar`;
            } else {
              return "/calendar";
            }
          }
        });
        api.addNavigationBarItem({
          name: "agenda",
          displayName: "Agenda",
          customFilter: category => {
            return siteSettings.events_agenda_enabled || category && category.events_agenda_enabled;
          },
          customHref: category => {
            if (category) {
              return `${category.url}/l/agenda`;
            } else {
              return "/agenda";
            }
          }
        });
        api.modifyClass("component:edit-category-settings", dt7948.p({
          pluginId: "discourse-events",
          availableViews(category) {
            let views = this._super(...arguments);
            if (category.get("custom_fields.events_agenda_enabled")) {
              views.push({
                name: _I18n.default.t("filters.agenda.title"),
                value: "agenda"
              });
            }
            if (category.get("custom_fields.events_calendar_enabled")) {
              views.push({
                name: _I18n.default.t("filters.calendar.title"),
                value: "calendar"
              });
            }
            return views;
          }
        }, [["method", "availableViews", [(0, _decorators.default)("category")]]]));
        api.modifyClass("controller:preferences/interface", dt7948.p({
          pluginId: "discourse-events",
          saveAttrNames(makeDefault) {
            let attrs = this._super(makeDefault);
            attrs.push("custom_fields");
            return attrs;
          }
        }, [["method", "saveAttrNames", [(0, _decorators.default)("makeThemeDefault")]]]));
        const user = api.getCurrentUser();
        if (user && user.admin) {
          api.modifyClass("model:site-setting", dt7948.p({
            pluginId: "discourse-events",
            allowsNone() {
              if (this.get("setting") === "events_timezone_default") {
                return "site_settings.events_timezone_default_placeholder";
              } else {
                this._super();
              }
            }
          }, [["method", "allowsNone", [(0, _decorators.default)("valid_values")]]]));
        }
        api.modifyClass("controller:topic", dt7948.p({
          pluginId: "discourse-events",
          subscribeDiscourseEvents() {
            this.unsubscribeDiscourseEvents();
            this.messageBus.subscribe(`/discourse-events/${this.get("model.id")}`, data => {
              switch (data.type) {
                case "rsvp":
                  {
                    if (data.rsvp) {
                      this.set(`model.event.${data.rsvp.type}`, data.rsvp.usernames);
                      if (this.currentUser) {
                        const userRsvp = data.rsvp.usernames.includes(this.currentUser.username);
                        if (userRsvp) {
                          this.set("model.event_user", {
                            rsvp: data.rsvp.type
                          });
                        }
                      }
                    }
                  }
              }
            });
          },
          unsubscribeDiscourseEvents() {
            this.messageBus.unsubscribe(`/discourse-events/${this.get("model.id")}`);
          }
        }, [["method", "subscribeDiscourseEvents", [(0, _decorators.observes)("model.id")]]]));
        api.modifyClass("service:composer", dt7948.p({
          pluginId: "discourse-events",
          eventValidation(action, event, eventsRequired, lastValidatedAt) {
            if (action === _composer.CREATE_TOPIC && eventsRequired && !event) {
              return _object.default.create({
                failed: true,
                reason: _I18n.default.t("composer.error.event_missing"),
                lastShownAt: lastValidatedAt
              });
            }
          },
          ensureEvent() {
            if (this.model && this.model.topic && this.model.topic.event && !this.model.event) {
              this.set("model.event", this.model.topic.event);
            }
          },
          // overriding cantSubmitPost on the model is more fragile
          save() {
            if (!this.get("eventValidation")) {
              this._super(...arguments);
            } else {
              this.set("lastValidatedAt", Date.now());
            }
          }
        }, [["method", "eventValidation", [(0, _decorators.default)("model.action", "model.event", "model.category.events_required", "lastValidatedAt")]], ["method", "ensureEvent", [(0, _decorators.observes)("model.composeState")]]]));
      });
    }
  };
});