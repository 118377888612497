define("discourse/plugins/discourse-events/discourse/templates/connectors/topic-list-main-link-bottom/topic-list-event", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.topic.event}}
    <div class="topic-list-item-event">
      <div class="topic-list-item-event-time">
        <a
          href={{@outletArgs.topic.lastUnreadUrl}}
          class={{@outletArgs.topic.topicListItemClasses}}
        >
          {{event-label @outletArgs.topic.event list="true"}}
        </a>
      </div>
      {{#if this.siteSettings.events_rsvp}}
        {{#if @outletArgs.topic.event.rsvp}}
          <div class="topic-list-item-event-rsvp">
            <a
              href={{@outletArgs.topic.lastUnreadUrl}}
              class={{@outletArgs.topic.topicListItemClasses}}
            >
              <span>{{@outletArgs.topic.event_going_total}}</span>
              <span>{{i18n "event_rsvp.going.label"}}</span>
            </a>
          </div>
        {{/if}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "E1QZBnjC",
    "block": "[[[41,[30,1,[\"topic\",\"event\"]],[[[1,\"  \"],[10,0],[14,0,\"topic-list-item-event\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"topic-list-item-event-time\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,1,[\"topic\",\"lastUnreadUrl\"]]],[15,0,[30,1,[\"topic\",\"topicListItemClasses\"]]],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,1,[\"topic\",\"event\"]]],[[\"list\"],[\"true\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"events_rsvp\"]],[[[41,[30,1,[\"topic\",\"event\",\"rsvp\"]],[[[1,\"        \"],[10,0],[14,0,\"topic-list-item-event-rsvp\"],[12],[1,\"\\n          \"],[10,3],[15,6,[30,1,[\"topic\",\"lastUnreadUrl\"]]],[15,0,[30,1,[\"topic\",\"topicListItemClasses\"]]],[12],[1,\"\\n            \"],[10,1],[12],[1,[30,1,[\"topic\",\"event_going_total\"]]],[13],[1,\"\\n            \"],[10,1],[12],[1,[28,[35,5],[\"event_rsvp.going.label\"],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"div\",\"a\",\"event-label\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/connectors/topic-list-main-link-bottom/topic-list-event.hbs",
    "isStrictMode": false
  });
});